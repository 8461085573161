<template>
  <div class="page">
    <div class="page-header">
      <el-button
        class="footer-btn"
        type="primary"
        icon="el-icon-plus"
        @click="addItem"
        v-has="'add'"
      >{{ $t('channelManagement.addChannel') }}</el-button>
      <el-button
        class="footer-btn"
        type="primary"
        plain
        icon="el-icon-plus iconfont icon-export"
        @click="exporyItem"
        v-has="'add'"
      >{{ $t('channelManagement.exportChannel') }}</el-button>
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item>
            <el-button
              type="primary"
              @click="sendMessage(tableSelection)"
              :disabled="!tableSelection.length"
              v-has="'sendEmail'"
              >{{$t('enterpriseSendEmail.sendNotification')}}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('channelManagement.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <table-pagination
        memory="channel"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        optionShow
        columnOptionShow
        selectionShow
        @selection-change="selectionChange"
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <template slot="userStatus" slot-scope="scope">
          <span :class="'status-' + scope.row.userStatus.toLowerCase()">{{scope.row.userStatus}}</span>
        </template>
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('edit')"
            placement="top"
            :open-delay="1000"
            v-has="'edit'"
          >
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)">
              <i class="el-icon-edit iconfont icon-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="scope.row.status ? $t('user.resetPaw') : $t('user.send')"
            placement="top"
            :open-delay="1000"
          >
            <el-button type="text" class="btn-p0" @click="sendItem(scope.row)">
              <i :class="scope.row.status ? 'el-icon-refresh-right' : 'el-icon-message'"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="scope.row.frozen ? $t('unfreeze') : $t('freeze')"
            placement="top"
            :open-delay="1000"
          >
            <el-button type="text" class="btn-p0" @click="freezeItem(scope.row)">
              <i :class="scope.row.frozen ? 'el-icon-unlock' : 'el-icon-lock'"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  channelSearch,
  channelExport,
  enterpriseFrozen,
  enterpriseUnfreeze,
} from '@/api/enterprise'
import { userSend } from '@/api/user'
import { TablePagination } from '@/components'
import { downloadFile } from '@/plugins/methods'

export default {
  name: 'Channel',
  components: {
    TablePagination,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'createTime',
        sortOrder: 'desc',
        keywords: null,
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('channelManagement.name'),
          prop: 'name',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('enterpriseManagement.email'),
          prop: 'email',
          minWidth: 200,
        },
        {
          label: this.$t('enterpriseManagement.contact'),
          prop: 'contact',
          minWidth: 200,
        },
        {
          label: this.$t('status'),
          prop: 'userStatus',
          minWidth: 150,
          filterEnable: true,
          slot: true
        },
        {
          label: this.$t('enterpriseManagement.area'),
          prop: 'area',
          minWidth: 150,
          filterEnable: true,
        },
        {
          label: this.$t('enterpriseManagement.deviceNumbers'),
          prop: 'deviceNum',
          minWidth: 150,
        },
        {
          label: this.$t('creator'),
          prop: 'createor',
          minWidth: 150,
        },
        {
          label: this.$t('createDate'),
          prop: 'createTime',
          minWidth: 150,
          filter: 'dateFormat',
          sortable: 'custom',
        },
      ],
      tableData: [],
      tableSelection: [],
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
    ...mapState('account', ['loginInfo']),
    ...mapGetters('session', ['pageTabelHeight']),
    tabelParams() {
      let timezone = this.loginInfo.timezone
      let data = { ...this.tabelSearch, timezone: timezone.slice(1, timezone.length - 1) }
      data.site = this.sidebarSite == '' ? null : this.sidebarSite
      return data
    },
  },
  watch: {
    'tabelParams.site'() {
      this.getTabelData()
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      channelSearch(this.tabelParams)
        .then((res) => {
          this.tableData = res.data.result.rows[0].list.map((e) => {
            return {
              ...e,
              userStatus: e.status ? (e.frozen ? this.$t('channelManagement.Frozen') : this.$t('channelManagement.Normal')) : this.$t('channelManagement.Inactivated'),
            }
          })
          this.tabelTotal = res.data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'createTime'
      this.getTabelData()
    },
    // 增
    addItem() {
      this.$router.push('/channelManagement/addChannel')
    },
    // 进入企业
    // enterItem(id) {
    //   this.$store
    //     .dispatch('account/EnterTheEnterprise', { id })
    //     .then(() => {
    //       this.$store.commit('memory/SET_SIDEBAR_SITE', '') // 清除站点
    //     })
    //     .catch(() => {})
    // },
    // 编辑
    editItem(id) {
      this.$router.push({ path: '/channelManagement/editChannel', query: { id } })
    },
    // 激活/忘记密码
    sendItem({ id, email, userName, status }) {
      userSend({
        id: id,
        email: email,
        userName: userName,
        type: status ? 0 : 1,
      })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
    // 冻结/解冻
    freezeItem({ id, frozen }) {
      const freezeApi = frozen ? enterpriseUnfreeze : enterpriseFrozen
      freezeApi({ id })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
    // 导出
    exporyItem() {
      channelExport(this.tabelParams).then((res) => {
        downloadFile(res)
      })
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 发送通知
    sendMessage(tableSelection) {
      this.$router.push({ path: '/enterpriseManagement/sendEmail', query: { tableSelection } })
    }
  },
}
</script>

<style lang="scss" scoped>
@mixin status-color($color) {
  color: $color;
}
.status-inactive {
  @include status-color(#666);
}
.status-normal {
  @include status-color(#6B489D);
}
.status-frozen {
  @include status-color(#F88273);
}
</style>